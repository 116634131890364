@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
/* Fonts */

.ewFont {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
}

.ewFontBold {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
}

html, body, #root {
    height: 100%;
    overflow-x: hidden;
}

.main-container {
    max-width: 100%;
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.custom-card {
    background-color: rgba(255,255,255,0.85) !important;  
}

.address-container {   
    margin-bottom: 12px;
}

.contact-container {
    margin-bottom: 12px;
    width: 100%;
}

.scrollable, .inner-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrollable {
    width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar, .inner-scroll::-webkit-scrollbar, .outer-scroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable, .inner-scroll, .outer-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.accountPanel {    
    display: inline-block;
    width:600px;
    margin:3px;
}

.fullHeight {
    height: 100%;
    max-height: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px;
}

#blooblar {
    overflow-y: scroll;
}

:disabled {
    cursor: not-allowed;
}

.modal650 {
    width:650px;
}

.carousel {
    background-color: rgba(119,136,153, 0.5);
    border-radius: 5px;
    margin-bottom: 15px;
}

.carousel-caption {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 15px;
}

.carousel-caption a {
    color: white;
}

.carousel-caption a:hover {
    text-decoration: underline;
    cursor: pointer;
}

/*.modal-header {
    background-color: #345391;
    color: white;
}*/

.modal-dialog-centered {
    margin: 0 auto !important;
}

.modal-content .close {
    color: white;
}

.delete-modal .close {
    color: black;
}

.delete-modal .modal-dialog {
    width: 300px;
}

.delete-modal .modal-content {
    background-color: rgba(52,83,145, 0.8) !important;
}

.delete-modal .modal-header {
    color: white !important;
}

/*.delete-modal .modal-header {
    background-color: rgba(255,255,255,0.4) !important;
}

.delete-modal .modal-title {
    background-color: rgba(255,255,255,0.4) !important;
}*/

.about-me-button {
    font-size: 1.1em;
    border: none !important;
    background-color: transparent;
    padding: 3px;
}

.about-me-button:visited {
    background-color: transparent !important;
    outline: none !important;
}

.about-me-button:hover {
    background-color: transparent;
    text-decoration: underline;
}

.about-me-button:active {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}

.about-me-button:focus {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 3px solid rgba(255, 255, 255, 0.3);
}

.experience-button {
    z-index: 1;
}
.experience-button.active {
    outline: 5px solid rgba(255, 255, 255, 0.3);
    z-index: 2 !important;
}

/*Customize checkbox label (the container)*/
.check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    border: 3px solid white;
    background-color: none;
}

/* On mouse-over, add a grey background color */
/*.check-container:hover input ~ .checkmark {
    background-color: #ccc;
}*/

/* When the checkbox is checked, increase size, add blue border */
.check-container input:checked ~ .checkmark {
    border-color: #6788ED;
    height: 35px;
    width: 35px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 10px;
    height: 20px;
    border: solid #6788ED;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.delete-button {
    /*background-color: #345391;*/
    color: #345391 !important;
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

.delete-button:visited {
    color: #345391;
    background-color:  rgba(255, 255, 255, 0);
    box-shadow: none !important;
    outline: 3px solid rgba(52,83,145, 0.3);
}

.delete-button:hover {
    color: #345391;
    outline: 3px solid rgba(52,83,145, 0.3);
    background-color: rgba(255, 255, 255, 0);
}

.delete-button:active, .delete-button:focus {
    color: #345391 !important;
    background-color: rgba(255, 255, 255, 0) !important;
    box-shadow: none !important;
    outline: 3px solid rgba(52,83,145, 0.3) !important;
}

.slideLeft {
    animation: slide-left 3s;
    animation-delay: 2s;
    opacity: 0;
    animation-fill-mode: forwards;
}

@keyframes slide-left {
    from {
        margin-left: 200px;
        margin-right: -200px;
        opacity: 0;
    }
    to {
        margin-left: 0;
        margin-right: 0;
        opacity: 1;     
    }
}

.appear {
    animation: appear 2s;
    animation-delay: 5s;
    opacity: 0;
    animation-fill-mode: forwards;
    margin-top:20px;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

    .main-container {
        width: 400px;
    }

    .address-search-results {
        width: 300px !important;
    }

    .icon-and-text {
        flex-direction: row !important;
    }

    .text-with-icon {
        padding-right: 2px;
    }

    .icon-with-text {
        padding-left: 2px;
        padding-right: 5px;
    }

    .el-team-text {
        font-size: 0.9em !important;
    }
    .el-team-heading {
        font-size: 1.7em !important;
    }
    .carousel {
        width: 300px !important;
    }
    .carousel-item {
        width: 300px !important;
    }
    .carousel img {
        height: 120px !important;
    }
    .carousel h4 {
        font-size: 1.2em;
        margin-bottom: 0px;
    }
    .carousel div {
        font-size: 0.96em;
        padding: 1px !important;
    }
    .carousel-caption {
        z-index: 1;
    }
    .carousel-control-prev, .carousel-control-next {
        z-index: 2;
    }
    .el-about-me {
        margin-bottom: 10px;
    }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
    
    .welcome-banner {
        flex-direction: column;
    }
    .main-container {
        justify-content: flex-start;
    }
    .outer-scroll {
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .inner-scroll {
        overflow-y: visible;
        overflow-x: visible;
    }
}

/* React-sortable-tree */
.rst__node {
    display: flex;
}

.rst__lineBlock {
    flex: 0 0 auto;
}

.rst__nodeContent {
    flex: 0 1 auto;
    left: auto !important;
    position: relative !important;
    top: auto !important;
    width: 100%;
}

.rst__highlightLineVertical::before {
    width: 0px;
}

.rst__highlightTopLeftCorner::before {
    border-top: none;
    border-left: none;
}

.rst__rtl.rst__highlightTopLeftCorner::before {
    border-right: none;
}

.rst__highlightBottomLeftCorner::before {
    border-bottom: none;
    border-left: none;
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
    border-right: none;
}

.rst__highlightBottomLeftCorner::after {
    border-left: none;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
    border-right: none;
}

.rst__rowSearchMatch {
    outline: solid 2px #7a5cf0;
}

.ew {
    color: #7a5cf0;
}
.grow {
    animation: grow 1s;
    animation-delay: 1s;
    animation-fill-mode: none;
    animation-iteration-count: 2;
    animation-direction: alternate;
}

@keyframes grow {
    from {
        font-size: 2.8em;
        height: 40px;
    }

    to {
        font-size: 5em;
        height: 60px;
    }
}

.docImage {
    height: 40px;
}

/* Scrollbar styles */
.scrollbar-container {
  position: relative;
  height: 100%;
}

.ps {
  overflow: hidden;
  touch-action: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0px;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  width: 15px;
  right: 0;
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute;
}

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
    max-height:100%;
    display: flex;
    flex-flow: column nowrap;    
}

.document-editor__toolbar {
    z-index: 1;
    box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );
    border-bottom: 1px solid var(--ck-color-toolbar-border);
}

    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

.document-editor__editable-container {
    padding: calc( 2 * var(--ck-spacing-large) );
    background: var(--ck-color-base-foreground);
    overflow-y: scroll;    
}

    .document-editor__editable-container .ck-editor__editable {
        width: 15.8cm;
        min-height: 21cm;
        padding: 1cm 2cm 2cm;
        border: 1px hsl( 0,0%,82.7% ) solid;
        border-radius: var(--ck-border-radius);
        background: white;
        box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );
        margin: 0 auto;
    }



.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    font: 16px/1.6 Roboto, Arial, sans-serif;
}

.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
    min-width: 6em;
}

.document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
}

.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    font-size: 14pt;
    font-weight: normal;
}

.document-editor .ck-content h2 {
    line-height: 1.37em;
    padding-top: .342em;
    margin-bottom: .142em;
    text-align: center;
}

.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    font-size: 11pt;
    font-weight: normal;    
}

.document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text);
}

.document-editor .ck-content h3 {
    line-height: 1.86em;
    padding-top: .171em;
    margin-bottom: .357em;
}

.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    font-size: 1.31em;
    font-weight: bold;
}

.document-editor .ck-content h4 {
    line-height: 1.24em;
    padding-top: .286em;
    margin-bottom: .952em;
}

.document-editor .ck-content p {
    font-size: 1em;
    line-height: 1.63em;
    padding-top: .5em;
    margin-bottom: 1.13em;
}

.document-editor .ck-content blockquote {
    font-family: Georgia, serif;
    margin-left: calc( 2 * var(--ck-spacing-large) );
    margin-right: calc( 2 * var(--ck-spacing-large) );
}


.ck.ck-editor__editable_inline {
    width: calc( 210mm + 2px );
    height: auto;
    padding: 0mm 12mm !important;
    box-sizing: border-box;
    border: 1px solid hsl( 0, 0%, 88% ) !important;
    background: hsl( 0, 0%, 100% );
    box-shadow: 0 2px 8px hsla( 0, 0%, 0%, .08 );
    margin: 10px auto;
    overflow: hidden !important;
}

.ck.ck-balloon-panel {
    z-index: 10001 !important;
}

.placeholder {
    padding: 4px 2px;
    outline-offset: -2px;
    line-height: 1em;
    margin: 0 1px;
    background-color: #ffff00;
}

    .placeholder::selection {
        display: none;
    }

.sectionholder {
    border-radius: 2px;
    border: 1px solid grey;
    background: #F0F8FF;
    padding: 5px;   
}

    .sectionholder::selection {
        display: none;
    }

