@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
    max-height:100%;
    display: flex;
    flex-flow: column nowrap;    
}

.document-editor__toolbar {
    z-index: 1;
    box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );
    border-bottom: 1px solid var(--ck-color-toolbar-border);
}

    .document-editor__toolbar .ck-toolbar {
        border: 0;
        border-radius: 0;
    }

.document-editor__editable-container {
    padding: calc( 2 * var(--ck-spacing-large) );
    background: var(--ck-color-base-foreground);
    overflow-y: scroll;    
}

    .document-editor__editable-container .ck-editor__editable {
        width: 15.8cm;
        min-height: 21cm;
        padding: 1cm 2cm 2cm;
        border: 1px hsl( 0,0%,82.7% ) solid;
        border-radius: var(--ck-border-radius);
        background: white;
        box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );
        margin: 0 auto;
    }



.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    font: 16px/1.6 Roboto, Arial, sans-serif;
}

.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
    line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
    min-width: 6em;
}

.document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
    transform: scale(0.8);
    transform-origin: left;
}

.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
    font-size: 14pt;
    font-weight: normal;
}

.document-editor .ck-content h2 {
    line-height: 1.37em;
    padding-top: .342em;
    margin-bottom: .142em;
    text-align: center;
}

.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
    font-size: 11pt;
    font-weight: normal;    
}

.document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
    color: var(--ck-color-list-button-on-text);
}

.document-editor .ck-content h3 {
    line-height: 1.86em;
    padding-top: .171em;
    margin-bottom: .357em;
}

.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
    font-size: 1.31em;
    font-weight: bold;
}

.document-editor .ck-content h4 {
    line-height: 1.24em;
    padding-top: .286em;
    margin-bottom: .952em;
}

.document-editor .ck-content p {
    font-size: 1em;
    line-height: 1.63em;
    padding-top: .5em;
    margin-bottom: 1.13em;
}

.document-editor .ck-content blockquote {
    font-family: Georgia, serif;
    margin-left: calc( 2 * var(--ck-spacing-large) );
    margin-right: calc( 2 * var(--ck-spacing-large) );
}


.ck.ck-editor__editable_inline {
    width: calc( 210mm + 2px );
    height: auto;
    padding: 0mm 12mm !important;
    box-sizing: border-box;
    border: 1px solid hsl( 0, 0%, 88% ) !important;
    background: hsl( 0, 0%, 100% );
    box-shadow: 0 2px 8px hsla( 0, 0%, 0%, .08 );
    margin: 10px auto;
    overflow: hidden !important;
}

.ck.ck-balloon-panel {
    z-index: 10001 !important;
}

.placeholder {
    padding: 4px 2px;
    outline-offset: -2px;
    line-height: 1em;
    margin: 0 1px;
    background-color: #ffff00;
}

    .placeholder::selection {
        display: none;
    }

.sectionholder {
    border-radius: 2px;
    border: 1px solid grey;
    background: #F0F8FF;
    padding: 5px;   
}

    .sectionholder::selection {
        display: none;
    }
